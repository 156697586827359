<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card id="table-sales-by-city">
          <v-card-title>
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headers" :items="cities" />
            <table-printer element-id="table-sales-by-city" />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="cities" :custom-sort="customSort" :loading="loading" hide-default-footer />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'MetaSalesByCityAnalytics',
    components: { PeriodSelector, TableExporter, TablePrinter },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        headers: [{
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.city'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'city'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.zipcode'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'zipcode'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.averageOrder'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'averageOrder'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.revenue'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'revenue'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.rate'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'rate'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesByCity.table.percentage'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'percentage'
        }],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['city'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        cities: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      customSort (items, index, isDesc) {
        const sort = isDesc[0] === true ? -1 : 1;
        if (index[0] === 'revenue') {
          return items.sort((a, b) => {
            return sort * (this.parseFormattedNumber(a.revenue) - this.parseFormattedNumber(b.revenue));
          });
        }
        if (index[0] === 'percentage') {
          return items.sort((a, b) => {
            return sort * (this.parseFormattedNumber(a.percentage) - this.parseFormattedNumber(b.percentage));
          });
        }
        if (index[0] === 'rate') {
          return items.sort((a, b) => {
            return sort * (this.parseFormattedNumber(a.rate) - this.parseFormattedNumber(b.rate));
          });
        }

        return items.sort((a, b) => {
          if (a[index[0]] < b[index[0]]) {
            return sort * -1;
          }
          if (a[index[0]] > b[index[0]]) {
            return sort * 1;
          }

          return 0;
        });
      },
      parseFormattedNumber (number) {
        if (typeof number !== 'string') {
          return number;
        }

        return parseFloat(number.replace(/\s/g, '').replace(',', '.'));
      },
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/sales/city?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (response.data !== undefined && typeof response.data === 'object') {
            this.cities = response.data;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
