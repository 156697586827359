<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('metaReport.metaActivity.distribution.subtitle.locations') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="tableOptions" :items="distributedAreasLocations" :loading="loading" hide-default-footer>
            <template #[`item.lastDistribution`]="{ item }">
              {{ formatDate(item.lastDistribution) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('metaReport.metaActivity.distribution.subtitle.details') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="tableOptions" :items="distributedAreasDetails" :loading="loading" hide-default-footer>
            <template #[`item.lastDistribution`]="{ item }">
              {{ formatDate(item.lastDistribution) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';

  export default {
    name: 'MetaDistributionAnalytics',
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        headers: [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'locationName'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.distribution.table.area'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'areaName'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.distribution.table.availableZones'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'availableZones'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.distribution.table.distributedZones'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'distributedZones'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.distribution.table.percentage'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'percentage'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.distribution.table.lastDistribution'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'lastDistribution'
        }],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['locationName'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        distributedAreasLocations: [],
        distributedAreasDetails: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatDate (date) {
        if (date === undefined) {
          return '';
        }

        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/distribution', { timeout: 60000 });
          if (response.data !== undefined) {
            this.distributedAreasLocations = response.data.results.locations;
            this.distributedAreasDetails = response.data.results.details;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      }
    }
  };
</script>
