<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('metaReport.metaActivity.salesByLocation.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.salesByProvider.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.salesByCity.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.salesBySource.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.productsSold.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.ingredientsSold.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.distribution.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaActivity.discounts.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.drivers.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-sales-by-location-analytics :loading="loading" :refresh="refreshSalesByLocation" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-sales-by-provider-analytics :loading="loading" :refresh="refreshSalesByProvider" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-sales-by-city-analytics :loading="loading" :refresh="refreshSalesByCity" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-sales-by-source-analytics :loading="loading" :refresh="refreshSalesBySource" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-products-sold-analytics :loading="loading" :refresh="refreshProductSold" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-ingredients-sold-analytics :loading="loading" :refresh="refreshIngredientSold" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="7">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-distribution-analytics :loading="loading" :refresh="refreshDistribution" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="8">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-discounts-analytics :loading="loading" :refresh="refreshDiscount" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="9">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <drivers-analytics scope="meta" :loading="loading" :refresh="refreshDrivers" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import MetaSalesByLocationAnalytics from '@/views/components/business/analytics/meta/MetaSalesByLocationAnalytics';
  import MetaSalesByProviderAnalytics from '@/views/components/business/analytics/meta/MetaSalesByProviderAnalytics';
  import MetaSalesByCityAnalytics from '@/views/components/business/analytics/meta/MetaSalesByCityAnalytics';
  import MetaSalesBySourceAnalytics from '@/views/components/business/analytics/meta/MetaSalesBySourceAnalytics';
  import MetaProductsSoldAnalytics from '@/views/components/business/analytics/meta/MetaProductsSoldAnalytics';
  import MetaIngredientsSoldAnalytics from '@/views/components/business/analytics/meta/MetaIngredientsSoldAnalytics';
  import MetaDistributionAnalytics from '@/views/components/business/analytics/meta/MetaDistributionAnalytics';
  import MetaDiscountsAnalytics from '@/views/components/business/analytics/meta/MetaDiscountsAnalytics';
  import DriversAnalytics from '@/views/components/business/analytics/DriversAnalytics';

  export default {
    name: 'MetaReports',
    components: { AlertNotification, MetaSalesByLocationAnalytics, MetaSalesByProviderAnalytics, MetaSalesByCityAnalytics, MetaSalesBySourceAnalytics, MetaProductsSoldAnalytics, MetaIngredientsSoldAnalytics, MetaDistributionAnalytics, MetaDiscountsAnalytics, DriversAnalytics },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refreshSalesByLocation: null,
        refreshSalesByProvider: null,
        refreshSalesByCity: null,
        refreshSalesBySource: null,
        refreshProductSold: null,
        refreshIngredientSold: null,
        refreshDistribution: null,
        refreshDiscount: null,
        refreshDrivers: null
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        this.loading = true;
        switch (tab) {
          case 0:
            this.refreshSalesByLocation = Date.now();
            break;
          case 1:
            this.refreshSalesByProvider = Date.now();
            break;
          case 2:
            this.refreshSalesByCity = Date.now();
            break;
          case 3:
            this.refreshSalesBySource = Date.now();
            break;
          case 4:
            this.refreshProductSold = Date.now();
            break;
          case 5:
            this.refreshIngredientSold = Date.now();
            break;
          case 6:
            this.refreshDistribution = Date.now();
            break;
          case 7:
            this.refreshDiscount = Date.now();
            break;
          case 8:
            this.refreshDrivers = Date.now();
            break;
        }
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
