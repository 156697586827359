<template>
  <v-container id="meta-sales-by-location">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12">
        <div v-if="Object.keys(datacollection).length > 0" class="smallChart">
          <bar-chart :chart-data="datacollection" :options="options" data-position="above" data-color="rgba(0, 0, 0, 1)" data-postfix="€" />
        </div>
        <div v-else>
          {{ $t('report.noresult') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import { displayPrice } from '@/util/PriceUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import BarChart from '@/views/components/common/chart/BarChart';

  export default {
    name: 'MetaSalesByProviderAnalytics',
    components: { BarChart, PeriodSelector },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        datacollection: {},
        options: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center'
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (item) => displayPrice(item.yLabel, 'EUR')
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: false
            }],
            yAxes: [{
              stacked: false,
              ticks: {
                min: 0,
                callback: (value) => {
                  return displayPrice(value, 'EUR');
                }
              }
            }]
          }
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/sales/provider?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (response.data.labels !== undefined && response.data.datasets !== undefined) {
            this.datacollection = response.data;
            this.datacollection.datasets.forEach((dataset) => {
              dataset.label = this.$i18n.t('order.tab.details.provider.' + dataset.label);
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
