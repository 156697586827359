<script>
  import { Bar, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  let dataPosition;
  let dataColor;
  let dataPostfix;
  const displayDataValues = function () {
    const chartInstance = this.chart;
    const ctx = chartInstance.ctx;
    ctx.font = 'bold 100% ' + chartInstance.config.options.defaultFontFamily;
    ctx.fillStyle = dataColor;
    ctx.shadowColor = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.letterSpacing = '-1px';

    this.data.datasets.forEach(function (dataset, i) {
      const meta = chartInstance.controller.getDatasetMeta(i);
      meta.data.forEach(function (bar, index) {
        const data = dataset.data[index];
        if (Number(data) > 0) {
          ctx.fillText(String(data).replace('.', ',') + dataPostfix, bar._model.x, bar._model.y + (dataPosition === 'above' ? -5 : 20));
        }
      });
    });
  };

  export default {
    name: 'BarChart',
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        required: true
      },
      dataPosition: {
        type: String,
        default: 'above'
      },
      dataColor: {
        type: String,
        default: '#000000'
      },
      dataPostfix: {
        type: String,
        default: ''
      },
      options: {
        type: Object,
        required: false
      }
    },
    mounted () {
      // this.chartData is created in the mixin.
      // If you want to pass options please create a local options object
      dataPosition = this.dataPosition;
      dataColor = this.dataColor;
      dataPostfix = this.dataPostfix;
      const options = this.options;
      options.scales.yAxes[0].ticks.suggestedMin = 10;
      options.scales.yAxes[0].ticks.suggestedMax = Math.max(10, this.chartData.datasets.reduce((max, entry) => Math.max(max, ...entry.data), 0) * 1.1);
      options.animation = {
        duration: 0,
        onProgress: displayDataValues,
        onComplete: displayDataValues
      };
      this.renderChart(this.chartData, options);
    }
  };
</script>
