<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card id="table-discounts-groups">
          <v-card-title>
            {{ $t('metaReport.metaActivity.discounts.subtitle.groups') }}
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="groupHeaders" :items="groups" />
            <table-printer element-id="table-discounts-groups" />
          </v-card-title>
          <v-data-table :headers="groupHeaders" :options="groupOptions" :items="groups" :loading="loading" hide-default-footer />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card id="table-discounts-all">
          <v-card-title>
            {{ $t('metaReport.metaActivity.discounts.subtitle.all') }}
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="discountHeaders" :items="discounts" />
            <table-printer element-id="table-discounts-all" />
          </v-card-title>
          <v-data-table :headers="discountHeaders" :options="discountOptions" :items="discounts" :loading="loading" hide-default-footer />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'MetaDiscountsAnalytics',
    components: { PeriodSelector, TableExporter, TablePrinter },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        discountHeaders: [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'locationName'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.code'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'code'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.amount'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'amount'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.percentage'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'percentage'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.orders'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'orders'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.revenues'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'revenues'
        }],
        discountOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['locationName'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        discounts: [],
        groupHeaders: [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'groupName'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.orders'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'orders'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.discounts.table.revenues'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'revenues'
        }],
        groupOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['orders'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        groups: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/discounts?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (response.data !== undefined && typeof response.data.results === 'object') {
            this.groups = response.data.results.groups;
            this.discounts = response.data.results.all;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
