<template>
  <v-container id="meta-sales-by-source">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12">
        {{ $t('metaReport.metaActivity.salesBySource.subtitleSource') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="table-sales-by-source">
          <v-card-title>
            <v-spacer />
            <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headersSources" :items="dataSources" />
            <table-printer element-id="table-sales-by-source" />
          </v-card-title>
          <v-data-table :headers="headersSources" :options="options" :items="dataSources" :loading="loading" hide-default-footer>
            <template #[`item.month`]="{ item }">
              {{ MONTH[item.month] }}
            </template>
            <template #[`item.part`]="{ item }">
              {{ item.part }}%
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ $t('metaReport.metaActivity.salesBySource.subtitleCategory') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        ...
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { MONTH, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'MetaSalesBySourceAnalytics',
    components: { PeriodSelector, TableExporter, TablePrinter },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        MONTH: MONTH,
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        headersSources: [{
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.year'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'year'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.month'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'month'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.totalTaxIncluded'),
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'totalTaxIncluded'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.part'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'part'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.count'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'count'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.category'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'category'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.name'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'name'
        }, {
          text: this.$i18n.t('metaReport.metaActivity.salesBySource.table.prefix'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'prefix'
        }],
        dataSources: [],
        dataCategories: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['category', 'name', 'year', 'month'],
          sortDesc: [false, false, true, true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/sales/source?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (Array.isArray(response.data.category) && Array.isArray(response.data.source)) {
            this.dataSources = response.data.source;
            this.dataCategories = response.data.category;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
