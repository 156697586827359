<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('report.activity.drivers.title') }}
            <v-spacer />
            <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
          </v-card-title>
          <v-data-table :mobile-breakpoint="0" :headers="headers" :items="drivers" :options="options" :loading="loading" hide-default-footer group-by="group" fixed-header height="600px">
            <template #[`group.header`]="{items, isOpen, toggle}">
              <th>
                <v-icon @click="toggle">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                {{ items[0].date }}
              </th>
              <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                {{ displayTotal(header.value, items) }}
              </th>
            </template>
            <template #[`body.prepend`]="{items}">
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.total') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayTotal(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.service') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayServices(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.avg') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayAvg(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.best') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayBest(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.worst') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayWorst(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.rank') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayRank(header.value) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.drivers.table.lastDeliveryDate') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ formatDate(header.lastDeliveryDate) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayISO, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'DriversAnalytics',
    components: { PeriodSelector },
    props: {
      scope: {
        type: String,
        default: 'partner'
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [],
        drivers: [],
        ranking: {},
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: ['group'],
          groupDesc: [false],
          multiSort: false,
          mustSort: false
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate: displayISO,
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/' + (this.scope === 'meta' ? 'meta/' : '') + 'drivers?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.drivers !== undefined) {
            this.drivers = [];
            this.setHeaders(response.data.drivers.labels);
            this.setDatasets(response.data.drivers.datasets);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      setHeaders (labels) {
        this.headers = [{
          text: '',
          align: 'left',
          filterable: false,
          sortable: false,
          value: 'service'
        }].concat(labels.map((label) => {
          return {
            text: label.title,
            align: 'center',
            filterable: false,
            sortable: false,
            value: label.id,
            lastDeliveryDate: label.lastDeliveryDate
          };
        }));
      },
      setDatasets (datasets) {
        datasets.forEach((dataset) => {
          dataset.service = this.$i18n.t('report.activity.drivers.service.' + dataset.service);
          this.drivers.push(dataset);
        });
        this.calculateRanking(datasets);
      },
      calculateRanking (datasets) {
        this.ranking = {};
        datasets.forEach((dataset) => {
          const drivers = Object.keys(dataset).filter((key) => key.startsWith('user') && !isNaN(dataset[key]));
          drivers.forEach((driverId) => {
            if (this.ranking[driverId] === undefined) {
              this.ranking[driverId] = { rank: 0, score: 0, scores: [] };
            }

            let rank = 1;
            drivers.forEach((otherDriverId) => {
              if (driverId !== otherDriverId && dataset[otherDriverId] > dataset[driverId]) {
                rank++;
              }
            });
            this.ranking[driverId].scores.push(Math.round((100 / drivers.length) * (drivers.length + 1 - rank)));
          });
        });

        Object.keys(this.ranking).forEach((driverId) => {
          this.ranking[driverId].score = this.ranking[driverId].scores.reduce((total, score) => total + score, 0) / this.ranking[driverId].scores.length;
        });

        const ranking = Object.keys(this.ranking).sort((a, b) => {
          const diff = this.ranking[b].score - this.ranking[a].score;
          if (diff === 0) {
            return this.ranking[b].scores.length - this.ranking[a].scores.length;
          }

          return diff;
        });
        Object.keys(this.ranking).forEach((driverId) => {
          this.ranking[driverId].rank = ranking.indexOf(driverId) + 1;
        });
      },
      countServices (key, items) {
        return items.reduce((total, item) => total + (item[key] === undefined ? 0 : 1), 0);
      },
      totalDeliveries (key, items) {
        return items.reduce((total, item) => total + (item[key] === undefined ? 0 : item[key]), 0);
      },
      avgDeliveries (key, items) {
        const deliveries = this.totalDeliveries(key, items);
        const services = this.countServices(key, items);

        return services > 0 ? deliveries / services : 0;
      },
      maxDeliveriesOnService (service) {
        return Object.keys(service).filter((key) => key.startsWith('user')).reduce((max, key) => service[key] > max ? service[key] : max, 0);
      },
      minDeliveriesOnService (service) {
        return Object.keys(service).filter((key) => key.startsWith('user')).reduce((min, key) => service[key] < min ? service[key] : min, 999);
      },
      displayAvg (key, items) {
        const avg = this.avgDeliveries(key, items);

        return avg > 0 ? String(avg.toFixed(2)) : '';
      },
      displayServices (key, items) {
        const services = this.countServices(key, items);

        return services > 0 ? String(services) : '';
      },
      displayTotal (key, items) {
        const total = this.totalDeliveries(key, items);

        return total > 0 ? String(total) : '';
      },
      displayBest (key, items) {
        let counter = 0;
        items.forEach((item) => {
          if (item[key] === this.maxDeliveriesOnService(item)) {
            counter++;
          }
        });

        const countServices = this.countServices(key, items);

        return counter + ' ' + this.$i18n.t('common.of') + ' ' + countServices + ' (' + ((counter / countServices) * 100).toFixed(2) + '%)';
      },
      displayWorst (key, items) {
        let counter = 0;
        items.forEach((item) => {
          if (item[key] === this.minDeliveriesOnService(item)) {
            counter++;
          }
        });

        const countServices = this.countServices(key, items);

        return counter + ' ' + this.$i18n.t('common.of') + ' ' + countServices + ' (' + ((counter / countServices) * 100).toFixed(2) + '%)';
      },
      displayRank (key) {
        return this.ranking[key] === undefined || this.ranking[key].rank === 0 ? '' : String(this.ranking[key].rank);
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
